import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/templates/single-page.js";
import Calendar from "../components/table";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Calendar mdxType="Calendar" />
    <br />
When Montclair Public Schools close for inclement weather, the Co-op closes as well.  Class delegates will contact you; PLEASE DO NOT CALL TEACHERS.  Closing updates are also available online at the [Montclair Public Schools' website](http://www.montclair.k12.nj.us).  
    <p>{`In the event of a delayed opening: AM classes will run from 10-12; PM classes start at 12:30; Lunch cancelled for Creative Class.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      